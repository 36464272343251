<template>
  <Form
    :id="widgetId"
    :form-fields="formFields"
    :form-error="formError"
    :button-text="buttonLabel"
    button-color="primary_dark"
    :disabled="formSubmitSuccess"
    :is-loading="isLoading"
    @submit="submit"
  />
</template>

<script lang="ts" setup>
import useFormNonFieldError from '@/composables/useFormNonFieldError'
import {
  PublicNewsletterSettings,
  PublicSubscribeRequest
} from '@/service/__generated-api'
import { FormInputType } from '@/types/utils'

const { t } = useI18n()

const props = defineProps<{
  widgetId: string
  settings: PublicNewsletterSettings
}>()

const buttonLabel = computed(() =>
  formSubmitSuccess.value ? t('subscribed') : t('subscribe')
)

const isLoading = ref(false)
const formSubmitSuccess = ref(false)
const { formError, setFormError, resetFormError } = useFormNonFieldError(
  t('form.something-went-wrong-try-again')
)

const formFields = computed(() => {
  const fields: FormInputType[] = [
    {
      label: t('form.e-mail.label'),
      name: 'email',
      rules: 'required|email',
      placeholder: t('form.e-mail.placeholder'),
      type: 'text'
    }
  ]

  if (props.settings.agreement) {
    fields.push({
      label: props.settings.agreement.text,
      name: 'agreement',
      rules: props.settings.agreement.is_required ? 'required' : '',
      type: 'checkbox'
    })
  }

  return fields
})

const emit = defineEmits<{ (event: 'success'): void }>()

const submit = async (formData: Omit<PublicSubscribeRequest, 'widget'>) => {
  resetFormError()
  isLoading.value = true
  const payload = {
    widget: props.widgetId,
    ...formData
  }

  try {
    await useApi().publicNewsletterSubscribeCreate(payload)
    emit('success')
    formSubmitSuccess.value = true
  } catch (error) {
    setFormError(error)
  } finally {
    isLoading.value = false
  }
}
</script>
