<template>
  <section class="newsletter-widget grid">
    <h2 class="title t-caption" v-html="widget.widget_title" />

    <div class="content-overlay">
      <div class="content">
        <div class="col col--left">
          <div class="subtitle t-h1" v-html="widget.title" />
          <div class="description t-h4" v-html="widget.description" />
          <BaseLink class="link" :link="widget.terms_of_service" />
        </div>
        <div
          class="col col--right"
          :class="{
            'col--thanks': formSubmitSuccess
          }"
        >
          <NewsletterForm
            v-if="!formSubmitSuccess"
            :widget-id="widget.id"
            :settings="widget.settings"
            @success="onSuccess"
          />
          <NewsletterThanks
            v-else
            :success-message="widget.success_message"
            :success-header="widget.success_header"
          />
        </div>

        <OutlineText
          stroke-width="1.5"
          class="decorative-text"
          text="NEWSLETTER"
        />
      </div>

      <BaseImage
        v-if="mobileImage"
        class="image image--mobile"
        :image="mobileImage"
        :img-sizes="[
          { w: 430, h: 932 },
          { w: 375, h: 667 },
          { w: 360, h: 640 }
        ]"
        sizes="100vw"
      />
      <BaseImage
        v-if="tabletImage"
        class="image image--tablet"
        :image="tabletImage"
        :img-sizes="[
          { w: 1024, h: 1366 },
          { w: 820, h: 1180 },
          { w: 768, h: 1024 }
        ]"
        sizes="100vw"
      />
      <BaseImage
        v-if="widget.image_desktop"
        class="image image--desktop"
        :image="widget.image_desktop"
        :img-sizes="[
          { w: 3840, h: 2160 },
          { w: 2560, h: 1440 },
          { w: 1920, h: 1080 },
          { w: 1600, h: 900 },
          { w: 1440, h: 900 }
        ]"
        sizes="100vw"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { PublicImage, PublicNewsletterWidget } from '@/service/__generated-api'
import { Widget } from '@/types/utils'

const props = defineProps<Widget<PublicNewsletterWidget>>()

const imageWithFallback = (image?: PublicImage | null) =>
  image ?? props.widget.image_desktop

const mobileImage = computed(() => imageWithFallback(props.widget.image_mobile))
const tabletImage = computed(() => imageWithFallback(props.widget.image_tablet))

const formSubmitSuccess = ref(false)
const onSuccess = () => setTimeout(() => (formSubmitSuccess.value = true), 1000)
</script>

<style scoped lang="scss">
.newsletter-widget {
  row-gap: rem(16px);

  @include media-up(md) {
    row-gap: rem(23px);
  }

  @include media-up(xl) {
    row-gap: rem(24px);
  }
}

.title {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  padding-block-start: rem(8px);
  font-size: rem(15px);
  line-height: 1;
  border-block-start: $base-border;

  @include media-up(xl) {
    @include t-label;
  }

  .contrast-mode & {
    color: $contrast-yellow;
  }
}

.content-overlay {
  @include media-column(
    (
      xs: 4,
      md: 8,
      lg: 12
    )
  );
  position: relative;
  z-index: 0;
  padding-block: rem(30px);
  padding-inline: rem(20px);
  background: $light-grey;

  @include media-up(md) {
    padding-block: rem(40px);
    padding-inline: rem(40px);
  }

  @include media-up(xl) {
    padding-block: rem(69px);
    padding-inline: rem(69px);
  }
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 54px;

  padding-block: rem(44px) rem(67px);
  padding-inline: rem(20px);
  overflow: hidden;
  background: $orange;

  @include media-up(md) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    padding-block: rem(47px) rem(74px);
    padding-inline: rem(30px);

    > .col {
      width: 50%;

      &--left {
        width: calc(50% - (17 / 768 * 100svw));
      }
    }
  }

  @include media-up(xl) {
    padding-block: rem(54px) rem(64px);
    padding-inline: rem(63px) rem(61px);

    > .col {
      &--left {
        width: 45%;
      }

      &--right {
        width: 43.75%;
      }
    }
  }
}

.col {
  &--left {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: rem(22px);

    @include media-up(md) {
      margin-block-end: rem(26px);
    }

    @include media-up(xl) {
      margin-block-end: 0;
    }

    &::before {
      display: block;
      margin-block-end: rem(5px);
      content: '';
      background-color: $black;
      inline-size: rem(60px);
      block-size: rem(3px);

      @include media-up(md) {
        margin-block-end: rem(15px);
      }

      @include media-up(xl) {
        margin-block-end: rem(14px);
        inline-size: rem(85px);
        block-size: rem(4px);
      }
    }
  }

  &--right {
    &:not(.col--thanks) {
      @include media-up(md) {
        align-self: flex-end;
        margin-block: rem(48px) 0;
      }

      @include media-up(xl) {
        align-self: center;
        margin-block: rem(102px - 54px) 0;
      }
    }
  }

  &--thanks {
    @include media-up(md) {
      align-self: center;
    }
  }
}

.subtitle {
  margin-block-end: rem(1px);

  @include media-up(md) {
    margin-block-end: 0;
  }

  @include media-up(xl) {
    margin-block-end: rem(6px);
  }
}

.description {
  @include media-up(md) {
    margin-block-end: rem(7px);
  }

  @include media-up(xl) {
    min-height: rem(64px);
    margin-block-end: rem(12px);
  }
}

.link {
  @include focus-visible;
  position: relative;

  width: fit-content;
  font-family: $font;
  font-size: rem(12px);
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  &::before {
    position: absolute;
    top: rem(-8px);
    left: 0;
    width: 100%;
    content: '';
    border-bottom: $base-border;
    opacity: 0;
    transition:
      opacity $transition,
      transform 0.4s ease-out;
    transform: scaleX(0);
    transform-origin: 0 50%;
  }

  &:hover,
  &:focus-visible {
    &::before {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.decorative-text {
  --el-w: 443;
  --el-h: 59.72;
  --device-w: 375;
  --margin: 0;
  --width: calc(var(--el-w) / var(--device-w) * 100svw);

  position: absolute;
  bottom: calc(
    var(--width) / (var(--el-w) / var(--el-h)) * (-0.25 - var(--margin))
  );
  left: calc(52 / 295 * 100%);
  width: var(--width);
  transform: rotateZ(7deg);
  transform-origin: left;

  @include media-up(md) {
    --el-w: 828;
    --device-w: 768;
    --el-h: 111;
    --margin: 0.12;

    left: calc(72 / 624 * 100%);
  }

  @include media-up(xl) {
    --el-w: 1244;
    --device-w: 1440;
    --el-h: 166;
    --margin: 0.2;

    left: calc(311 / 1220 * 100%);
  }
}

.image {
  position: absolute;
  inset: 0;
  z-index: -1;

  &--mobile {
    @include hide-up(md);
  }

  &--tablet {
    @include hide-up(xl);
    @include hide-down(md);
  }

  &--desktop {
    @include hide-down(xl);
  }
}
</style>
